import { FiArrowRight } from "react-icons/fi";
import { BsMotherboard, BsTaxiFrontFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import UnMatchedTechnology from "../../assets/img/home/Inside img/UnMatchedTechnology_1.webp";
import FeactureOfZervX from "../../assets/img/home/Inside img/Feacture Of ZervX.webp";
import TransformTaxiOperations from "../../assets/img/home/Inside img/Innovative Way to Transform Taxi Operations.webp";
import BookingProcess from "../../assets/img/home/Inside img/BookingProcess_4.webp";
import Link from "next/link";
import { TbChartCandle } from "react-icons/tb";
import { MdOutlinePhonelink } from "react-icons/md";
import Image from "next/future/image";
const FeatureImgContentOne = () => {
  return (
    <>
      <main className="why-choose-us ptb-120 " id="ZervX">
        <section className="container">
          <article className="row justify-content-lg-between align-items-center">
            <article className="col-lg-5 col-12">
              <article className="why-choose-content">
                <article className="icon-box rounded-custom bg-btn-green shadow-sm d-inline-block">
                  <span className="fas text-white">
                    <BsMotherboard />
                  </span>
                </article>
                <h2>Taxi Software Tailored For Your Success</h2>
                <p>
                  The most powerful, end-to-end technology platform for
                  launching and scaling mobility operations.
                </p>
                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Intuitive Interface
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Real-Time Stats
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Efficient Navigation
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    User-Friendly
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Multi-Language Support
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    White Labeling
                  </li>
                </ul>
                <div
                  className="read-more-link  text-decoration-none  text-charcoal"
                >
                  <Link
                    href="/mobility-platform/app-for-booking-taxi" passHref
                  ><a  className=" text-black ">
                    <span role="button">
                      Discover What Makes Us Different
                      <span className="far ms-2 mb-1">
                        <FiArrowRight />
                      </span>
                    </span>
                    </a>
                  </Link>
                </div>
              </article>
            </article>
            <article className="col-lg-6 col-12">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0 text-center position-relative">
                <Image
                  width={636}
                  height={477}
                  src={UnMatchedTechnology}
                  layout="responsive"
                  className="img-fluid"
                  alt="Advanced taxi dispatch software"
                  quality={20}
                  loading="lazy"
                />
              </div>
            </article>
          </article>
        </section>
      </main>
      <main className="image-feature pt-60 pb-120">
        <section className="container">
          <article className="row justify-content-between align-items-center">
            <article className="col-lg-5 col-12 order-lg-1">
              <article className="feature-img-content">
                <article className="icon-box rounded-custom bg-btn-green-dark shadow-sm d-inline-block">
                  <span className="fal text-white">
                    <TbChartCandle />
                  </span>
                </article>
                <h2>Stay Ahead of the Competition</h2>
                <p>
                  Equip your taxi business with our taxi dispatching system to surpass the competition and deliver outstanding service.
                </p>
                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Advanced Technology
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Continuous Improvement
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Technical Support
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Customized Solutions
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Market Insights
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Global Reach
                  </li>
                </ul>
                <div
                  className="read-more-link text-decoration-none text-dark d-block mt-4"
                >
                  <Link
                    href="/mobility-platform/alternative-to-uber-clone-software" passHref
                  ><a className=" text-black ">
                    <span role="button">
                      Explore Our Next-Gen Platform
                      <span className="far ms-2 mb-1">
                        <FiArrowRight />
                      </span>
                    </span>
                    </a>
                  </Link>
                </div>
              </article>
            </article>
            <article className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0 text-center">
                <Image
                  width={636}
                  height={477}
                  src={FeactureOfZervX}
                  className="img-fluid"
                  alt="Taxi software with innovative features"
                  loading="lazy"
                />
              </div>
            </article>
          </article>
        </section>
      </main>
      <main className="why-choose-us pt-60 pb-120">
        <section className="container">
          <article className="row justify-content-lg-between align-items-center">
            <article className="col-lg-5 col-12">
              <article className="why-choose-content">
                <article className="icon-box rounded-custom bg-btn-green shadow-sm d-inline-block">
                  <span className="fas text-white">
                    <BsTaxiFrontFill />
                  </span>
                </article>
                <h2>Grow Your Taxi Business Faster</h2>
                <p>
                  Our white label taxi software is not just another taxi app or dispatch system. It is a comprehensive solution that can revolutionize the taxi business.
                </p>
                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Real-time Fleet Management
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Automated Dispatch
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Powerful Analytics
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Seamless Integration
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Exceptional Support
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Accurate Fare Calculation
                  </li>
                </ul>

                <div
                  className="read-more-link text-dark text-decoration-none"
                >
                  <Link
                    href="/mobility-platform/cab-booking-app-for-taxi-services" passHref
                  ><a  className=" text-black ">
                    <span role="button">
                      Discover Our Advanced Taxi Solution
                      <span className="far ms-2 mb-1">
                        <FiArrowRight />
                      </span>
                    </span>
                    </a>
                  </Link>
                </div>
              </article>
            </article>
            <article className="col-lg-6 col-12">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0 text-center">
                <Image
                  width={636}
                  height={477}
                  src={TransformTaxiOperations}
                  className="img-fluid"
                  alt="The best white label taxi software"
                  loading="lazy"
                />
              </div>
            </article>
          </article>
        </section>
      </main>
      <main className="image-feature pt-60 pb-120">
        <section className="container">
          <article className="row justify-content-between align-items-center">
            <article className="col-lg-5 col-12 order-lg-1">
              <article className="feature-img-content">
                <article className="icon-box rounded-custom bg-btn-green-dark shadow-sm d-inline-block">
                  <span className="fal text-white">
                    <MdOutlinePhonelink />
                  </span>
                </article>
                <h2>Get More Bookings and More Business</h2>
                <p>
                  Powerful booking engine for increased bookings and revenue.
                  User-friendly platform for enhanced customer experience.
                </p>
                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Booking Management
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Loyalty Program
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Comprehensive Dashboard
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Metrics and Reports
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Dynamic Fare Management
                  </li>
                  <li className="py-1">
                    <FaCheckCircle className="fas me-2 bg-color mb-1" />
                    Feedback Mechanisms
                  </li>
                </ul>
                <div
                  className="read-more-link text-decoration-none text-dark d-block mt-4"
                >
                  <Link
                    href="/mobility-platform/taxi-cab-dispatch-software" passHref
                  >
                    <a  className=" text-black ">
                    <span role="button">
                      Learn About Tailor-Made Booking Benefits
                      <span className="far ms-2 mb-1">
                        <FiArrowRight />
                      </span>
                    </span>
                    </a>
                  </Link>
                </div>
              </article>
            </article>
            <article className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0 text-center">
                <Image
                  width={636}
                  height={477}
                  layout="responsive"
                  src={BookingProcess}
                  className="img-fluid"
                  alt="ZervX ride hailing software interface"
                  loading="lazy"
                />
              </div>
            </article>
          </article>
        </section>
      </main>
    </>
  );
};
export default FeatureImgContentOne;
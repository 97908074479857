import React from "react";
import Link from "next/link";
import RideHailing from "../../assets/img/home/HomePageIcons/Ride Hailing.svg";
import RideSharing from "../../assets/img/home/HomePageIcons/Ride Sharing.svg";
import CorporateTransportation from "../../assets/img/home/HomePageIcons/Corporate Transportation.svg";
import PackageDelivery from "../../assets/img/home/HomePageIcons/Package Delivery.svg";
import CourierService from "../../assets/img/home/HomePageIcons/Courier Service.svg";
import MaterialsDelivery from "../../assets/img/home/HomePageIcons/Materials Delivery.svg";
import HomeImg from "../../assets/img/home/Inside img/ZervXBanner.webp";
import Image from "next/future/image";
import { FiArrowRight } from "react-icons/fi";
const HeroSectionOne = ({ props }) => {
  const zervXServices = [
    {
      img: RideHailing,
      title: `Ride ${""}  `,
      content: "Hailing",
      Link: "/industries/ride-hailing",
      imgalt: "Ride hailing icon",
      imgtitle: "Ride hailing",
    },
    {
      img: RideSharing,
      title: "Ride ",
      content: " Sharing",
      Link: "/industries/ride-sharing",
      imgalt: "Ride sharing icon",
      imgtitle: "Ride sharing",
    },
    {
      img: CorporateTransportation,
      title: "Corporate Transportation",
      Link: "/industries/corporate-transportation",
      imgalt: "Corporate transportation icon",
      imgtitle: "Corporate transportation",
    },
    {
      img: CourierService,
      title: "Courier Service",
      Link: "/industries/city-couriers",
      imgalt: "Courier service icon",
      imgtitle: "Courier service",
    },
    {
      img: PackageDelivery,
      title: "Package Delivery",
      Link: "/industries/package-delivery",
      imgalt: "Package delivery icon",
      imgtitle: "Package delivery",
    },
    {
      img: MaterialsDelivery,
      title: "Materials Delivery",
      Link: "/industries/materials-delivery",
      imgalt: "Materials delivery icon",
      imgtitle: "Materials delivery",
    },
  ];
  return (
    <main
      className={` pt-100  pb-5 text-white bg-gradient  HeightSet`} 
    >
      <section className="container ">
        <article className="row  align-items-center justify-content-center mb-4">
          <article className="col-lg-6 col-md-10 px-md-0 px-4 d-flex align-items-center justify-md-content-center">
            <article className="hero-content-wrap mt-md-5 mt-lg-0 mt-xl-0 pt-5 ps-md-4 text-center">
              <h1 className="fw-semibold display-5 fs-1 text-md-start text-center fontCircularstd pb-1 pe-md-5">
                Your Premier Taxi Dispatch Software Solution
              </h1>
              <p
                className="lead pb-3 fontCircularstd text-md-start pe-md-4 "
                style={{ lineHeight: 1.3 }}
              >
                A streamlined platform designed exclusively for businesses and the on-demand transportation industry.
              </p>
              <div
              className="  action-btns mt-4 mb-md-0 mb-3 d-flex align-items-center justify-content-md-start justify-content-center flex "
              
              >
                <Link href="/industries/contact-sales" >
                <a
                  href="#ZervX"
                  className=" me-3  text-light fs-6 color p-2 px-4 px-md-4 d-flex align-items-center   bg-gradient_btn"
                 
                >
                  <span>
                  Get Started
                  </span>
                  <span style={{marginLeft: 5,marginTop: -2}}>
                  <FiArrowRight />
                  </span>
                </a>
                </Link>
              </div>
            </article>
          </article>
          <article className="col-lg-5 col-md-8 d-flex justify-content-end">
            <div className="hero-img position-relative circle-shape-images_tw pl-4 flex ms-xl-5  py-md-2">
              <Image
                src={HomeImg}
                alt="ZervX all-in-one mobility software"       
                className="img-fluid"
                height={420}
                width={390}
                priority
                rel="preload"
              />
            </div>
          </article>
        </article>
        <article className="row d-flex flex-wrap justify-content-center cursor-pointerm  pt-4 pb-5">
          {zervXServices.map((each, index) => (
            <Link href={each.Link} key={index + 1} passHref>
              <article
                role="button"
                key={index}
                className=" service  fw-bold text-white  mx-xl-1 col-lg-1 col-md-1 col-1 text-center flex-wrap d-flex justify-content-center align-items-center mb-3 ms-xl-1"
                style={{ width: "140px" }}
              >
                <div className=" p-2 py-2">
                  <Image
                    src={each.img}
                    alt={each.imgalt}
                    title={each.imgtitle}
                    className=""
                    width={37}
                    height={37}
                    loading="lazy"
                  />
                  <p className=" text-center mb-0 lineHeight bold">
                    {each.title}
                  </p>
                  <p className=" text-center mb-0 lineHeight bold ">
                    {each.content}
                  </p>
                </div>
              </article>
            </Link>
          ))}
        </article>
      </section>
    </main>
  );
};
export default HeroSectionOne;